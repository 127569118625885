import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Toast, Dialog, Checkbox, Step, Steps } from 'vant'

Vue.use(Step)
Vue.use(Steps)
Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'claimDetail',
  components: {},
  data () {
    return {
      startPosition: 0,
      showImage: false,
      images: [],
      showPopover: false,
      showPopover1: false,
      checked: true,
      log: [],
      icon1: require('../../assets/image/shizhong.png'),
      icon2: require('../../assets/image/quan.png'),
      claimDetail: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      },
      isxia: false,
      ishave: true,


    }
  },
  beforeCreate () {

  },
  created () {
    this.claimsDetail({ id: this.$route.query.claimsId }).then(res => {
      if (res.code == 200) {
        this.claimDetail = res.data
        this.log = this.claimDetail.logList
        if (this.log.length > 2) {
          this.ishave = true
        } else {
          this.ishave = false
        }
        var logList1 = this.log.concat()
        this.claimDetail.logList = logList1.splice(0, 2)
        this.claimDetail.bxClaimsCertificateImgs = res.data.bxClaimsCertificateImgs == '' || res.data.bxClaimsCertificateImgs == null ? [] : res.data.bxClaimsCertificateImgs.split(',')
        this.claimDetail.bxClaimsCaseHistoryImgs = res.data.bxClaimsCaseHistoryImgs == '' || res.data.bxClaimsCaseHistoryImgs == null ? [] : res.data.bxClaimsCaseHistoryImgs.split(',')

        this.claimDetail.bxClaimsBankImgs = res.data.bxClaimsBankImgs == '' || res.data.bxClaimsBankImgs == null ? [] : res.data.bxClaimsBankImgs.split(',')
        this.claimDetail.bxClaimsCostProveImgs = res.data.bxClaimsCostProveImgs == '' || res.data.bxClaimsCostProveImgs == null ? [] : res.data.bxClaimsCostProveImgs.split(',')
        // 新增三个

        this.claimDetail.bxClaimsPathologicalDiagnosisReportImgs = res.data.bxClaimsPathologicalDiagnosisReportImgs == '' || res.data.bxClaimsPathologicalDiagnosisReportImgs == null ? [] : res.data.bxClaimsPathologicalDiagnosisReportImgs.split(',')
        this.claimDetail.bxClaimsPrescriptionDrugImgs = res.data.bxClaimsPrescriptionDrugImgs == '' || res.data.bxClaimsPrescriptionDrugImgs == null ? [] : res.data.bxClaimsPrescriptionDrugImgs.split(',')
        this.claimDetail.bxClaimsGeneticTestingReportImgs = res.data.bxClaimsGeneticTestingReportImgs == '' || res.data.bxClaimsGeneticTestingReportImgs == null ? [] : res.data.bxClaimsGeneticTestingReportImgs.split(',')
      }
    })
  },
  methods: {
    ...mapActions('claims', ['claimsDetail', 'revokeClaims']),
    showImageList (List, index) {
      this.showImage = true
      this.images = List
      this.startPosition = index

    },
    back () {
      this.$router.go(-1)
    },
    changeOne () {
      this.showPopover = true
    },
    noxia () {
      this.isxia = false
      var logList = this.log.concat()
      this.claimDetail.logList = logList.splice(0, 2)
    },
    shixia () {
      this.isxia = true
      this.claimDetail.logList = this.log

    },
    changeOne1 () {
      this.showPopover1 = true
    },
    torevoke (id) {
      var obj = {
        bxClaimsId: id,
        deleteStatus: 1
      }
      this.revokeClaims(obj).then(res => {
        if (res.code == 200) {
          Toast('撤销成功')
          this.$router.push({ name: 'ayclaimList' })
        } else {
          Toast(res.msg)
        }
      })


    },
    toEdit (id, state) {
      if (state == 0) {
        this.$router.push({ name: 'ayeditClaim', query: { bxClaimsId: id, isdetail: 1 } })
      } else {
        this.$router.push({ name: 'aysupplement', query: { bxClaimsId: id, state: 2 } })
      }

    }








  },
}
